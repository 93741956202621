import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p className="footer-text">
          Copyright 2024 © gamefunnz.com - All rights reserved -
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms &amp; Conditions</a>
          <a href="/cookie">Cookie Policy</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
